<template>
  <el-card class="juke" :class="{ isMobile }">
    <top-title title="聚客量化平台"></top-title>
    <el-row>
      <el-col :span="12">
        <img src="/static/img/jks/jk1.png" />
      </el-col>
      <el-col :span="12">
        <div class="hint">
          <div class="title">多窗口多周期行情分析</div>
          <p class="content">
            聚客量化交易平台，支持多窗口、多周期行情分析，亦支持弹窗、微信预警功能。聚客量化交易平台内置包含订单流在内的多款指标分析工具，为用户行情分析判断和操作体验带来极大便利。
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="hint">
          <div class="title">量化任务运行展示</div>
          <p class="content">
            聚客量化交易平台是聚客量化团队自主研发的，具有自主知识产权的量化交易平台，平台支持期货、股票量化回测和交易，以及行情分析。聚客量化交易平台内置多款优质策略模型和风控工具供用户学习。
          </p>
        </div>
      </el-col>
      <el-col :span="12">
        <img src="/static/img/jks/jk2.png" />
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>

<style lang="scss" scoped>
.juke {
  text-align: center;
  .el-row {
    max-width: 1400px;
    margin: 0 auto;
    text-align: left;

    .el-col {
      padding: 0 20px;
    }
    img {
      width: 100%;
    }
    .hint {
      max-width: 680px;
      height: 150px;
      margin-top: 75px;
      .title {
        font-weight: 600;
        text-align: left;
      }

      .content {
        text-align: left;
        line-height: 40px;
      }
    }
  }
}

.juke.isMobile {
  text-align: center;
  .el-row {
    margin: 10px auto;
    text-align: left;
    height: 140px;

    .el-col {
      padding: 0 10px;
    }
    img {
      width: 100%;
    }
    .hint {
      height: 80px;
      margin-top: 4px;
      .title {
        font-weight: 600;
        text-align: left;
        font-size: 12px;
      }

      .content {
        text-align: left;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}
</style>
