<template>
  <div class="top-title" :class="{ isMobile }">
    <el-space>
      <div class="bar bar1"></div>
      <div class="title">{{ title }}</div>
      <div class="bar bar2"></div>
    </el-space>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

.
<style lang="scss" scoped>
.top-title {
  text-align: center;
  .el-space {
    width: 600px;
  }
  .bar {
    height: 10px;
    width: 200px;
  }

  .bar1 {
    background-image: linear-gradient(to right, #ffffff, #44546a);
  }

  .bar2 {
    background-image: linear-gradient(to right, #44546a, #ffffff);
  }
  .title {
    width: 160px;
    padding: 20px;
    font-size: 24px;
    font-weight: 600;
  }
}

.isMobile.top-title {
  .el-space {
    display: flex;

    :deep(.el-space_item) {
      width: 33%;
    }
  }
  .bar {
    flex: 1;
    height: 10px;
    width: 80px;
  }
  .title {
    width: 120px;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .el-space {
    width: 100%;
  }
}
</style>
