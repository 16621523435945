<template>
  <el-card class="juke" :class="{ isMobile }">
    <top-title title="聚客量化平台"></top-title>
    <el-row>
      <el-col :span="12">
        <img src="/static/img/jks/jk3.png" />
      </el-col>
      <el-col :span="12">
        <div class="hint">
          <div class="title">自定义策略模型编写框架</div>
          <p class="content">
            聚客量化交易平台使用当前最主流的编程语言：python，为用户实现自主策略研究和学习，提供了高效的开发框架。框架内，平台框架提供了完善的策略因子和常规函数库，也内置了主流指标算法。
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="hint">
          <div class="title">海量优质策略模型</div>
          <p class="content">
            聚客量化团队，经过多年在金融市场深耕实践，总结和整理出数十款优质的量化策略模型，用户无需编程，开箱即用，大大降低了用户在量化领域的专业门槛，真正的让人闲下来，让钱忙起来。
          </p>
        </div>
      </el-col>
      <el-col :span="12">
        <img src="/static/img/jks/jk4.png" />
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>

<style lang="scss" scoped>
.juke {
  text-align: center;
  .el-row {
    max-width: 1400px;
    margin: 0 auto;
    text-align: left;

    .el-col {
      padding: 0 20px;
    }
    img {
      width: 100%;
    }
    .hint {
      max-width: 680px;
      height: 150px;
      margin-top: 75px;
      .title {
        font-weight: 600;
        text-align: left;
      }

      .content {
        text-align: left;
        line-height: 40px;
      }
    }
  }
}

.juke.isMobile {
  text-align: center;
  .el-row {
    margin: 10px auto;
    text-align: left;
    height: 140px;

    .el-col {
      padding: 0 10px;
    }
    img {
      width: 100%;
    }
    .hint {
      height: 80px;
      margin-top: 4px;
      .title {
        font-weight: 600;
        text-align: left;
        font-size: 12px;
      }

      .content {
        text-align: left;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}
</style>
