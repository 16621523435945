<template>
  <div class="company">
    <intelligence></intelligence>
    <br />
    <example></example>
    <br />
    <market></market>
    <br />
    <team></team>
    <br />
    <juke></juke>
    <br />
    <juke2></juke2>
    <br />
    <certificate></certificate>
  </div>
</template>

<script>
import Example from './components/Example.vue'
import Intelligence from './components/Intelligence.vue'
import Juke from './components/Juke.vue'
import Juke2 from './components/Juke2.vue'
import Market from './components/Market.vue'
import Team from './components/Team.vue'
import Certificate from './components/Certificate.vue'
export default {
  components: {
    Example,
    Intelligence,
    Juke,
    Juke2,
    Market,
    Team,
    Certificate
  }
}
</script>

<style scoped>
.company {
  text-align: center;
}
</style>
