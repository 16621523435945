<template>
  <el-card class="ai" :class="{ isMobile }">
    <top-title title="AI与算法交易"></top-title>
    <br />
    <el-row class="el-row">
      <el-col :span="16">
        <div class="pic"></div>
      </el-col>
      <el-col :span="8">
        <div class="list">
          <div class="list-item">
            <div class="img-box1">
              <img src="/static/img/ais/ai2.png" />
            </div>
            <div class="title">数学模型</div>
          </div>
          <br />
          <div class="list-item">
            <div class="img-box2">
              <img src="/static/img/ais/ai3.png" />
            </div>
            <div class="title">算法交易</div>
          </div>
          <br />
          <div class="list-item">
            <div class="img-box1">
              <img src="/static/img/ais/ai3.png" />
            </div>
            <div class="title">深度学习</div>
          </div>
          <br />
          <div class="list-item">
            <div class="img-box2">
              <img src="/static/img/ais/ai4.png" />
            </div>
            <div class="title">人工智能</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>
<style lang="scss" scoped>
.ai {
  .el-row {
    min-width: 800px;
    max-width: 1400px;
    margin: 0 auto;
  }
  .pic {
    width: 100%;
    height: 600px;
    background: url('/static/img/ais/ai1.png') no-repeat;
    background-size: contain;
  }
  .list {
    max-width: 400px;

    .list-item {
      height: 120px;
      display: inline-flex;
    }
  }
  .title {
    font-size: 24px;
    line-height: 80px;
    margin-left: 20px;
  }

  .img-box1 {
    width: 80px;
    height: 80px;
    background: #44546a;
    border-radius: 40px;
    text-align: center;
    img {
      width: 40px;
      height: 40px;
      margin: 20px;
    }
  }

  .img-box2 {
    width: 80px;
    height: 80px;
    background: #a6a6a6;
    border-radius: 40px;
    text-align: center;
    float: left;
    img {
      width: 40px;
      height: 40px;
      margin: 20px;
    }
  }
}

.isMobile.ai {
  .el-row {
    min-width: 100%;
    max-width: 1400px;
  }

  .pic {
    height: 160px;
  }

  .list {
    padding-top: 6px;
    .list-item {
      display: flex;
      align-items: center;
      height: 30px;
      display: inline-flex;
      margin-bottom: 6px;
    }
  }
  .title {
    font-size: 12px;
    line-height: 40px;
  }

  .img-box1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #44546a;
    border-radius: 40px;
    text-align: center;
    img {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  .img-box2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #a6a6a6;
    border-radius: 40px;
    text-align: center;
    float: left;
    img {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }
}
</style>
