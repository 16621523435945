<template>
  <el-card class="market" :class="{ isMobile }">
    <top-title title="其它市场"></top-title>
    <br />
    <el-row style="max-width: 1400px; margin: 0 auto">
      <el-col :span="8">
        <el-card>
          <img src="/static/img/market/m1.png" />
          <h3>港股美股</h3>
          <p>海外股票市场量化策略和辅助工具定制开发，量化平台框架定制</p>
          <div class="number">1</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <img src="/static/img/market/m2.png" />
          <h3>金融衍生品</h3>
          <p>MT4、MT5、Tradingview等金融市场指标、EA、跟单社区定制搭建</p>
          <div class="number" style="background: #a6a6a6">2</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <img src="/static/img/market/m3.png" />
          <h3>区块链</h3>
          <p>应用于金融、公共服务、万物互联、公益版权等领域的数据结构底层</p>
          <div class="number">3</div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>
<style lang="scss" scoped>
.market {
  text-align: center;

  .el-card {
    width: 300px;
    height: 400px;
    margin: 0 auto;
    img {
      width: 60px;
      height: 60px;
    }

    .number {
      color: #ffffff;
      font-size: 36px;
      font-weight: 600;
      line-height: 60px;
      background: #44546a;
      height: 60px;
      width: 60px;
      border-radius: 30px;
      margin: 130px auto;
    }
  }
}

.isMobile.market {
  .el-card {
    position: relative;
    width: 100px;
    height: 200px;
    margin: 0 0px;

    .el-card__body {
      width: 200px;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        font-size: 10px;
        margin: 0;
      }

      p {
        font-size: 6px;
      }

      .number {
        display: none;
        position: absolute;
        bottom: 12px;
        color: #ffffff;
        font-size: 10px;
        font-weight: 600;
        background: #44546a;
        border-radius: 30px;
        margin: 10px 0;
      }
    }
  }
}
</style>
