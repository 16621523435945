<template>
  <el-card class="example" :class="{ isMobile }">
    <top-title title="开发案例"></top-title>
    <br />
    <el-space class="content">
      <el-card :body-style="{ padding: 0, maxWidth: '460px' }">
        <img src="/static/img/examples/exp1.png" />
        <div class="footer" title="国内商品期货上的多周期智能分析指标">
          国内商品期货上的多周期智能分析指标
        </div>
      </el-card>
      <el-card :body-style="{ padding: '0px', maxWidth: '460px' }">
        <img src="/static/img/examples/exp2.png" />
        <div class="footer" title="国内期货螺纹钢品种上的量化模型案例">
          国内期货螺纹钢品种上的量化模型案例
        </div>
      </el-card>
      <el-card :body-style="{ padding: '0px', maxWidth: '460px' }">
        <img src="/static/img/examples/exp3.png" />
        <div
          class="footer"
          title="国内A股市场的针对可转债ETF开放式基金的程序化交易模型案例"
        >
          国内A股市场的针对可转债ETF开放式基金的程序化交易模型案例
        </div>
      </el-card>
    </el-space>
    <br />
    <p class="desc">
      聚客量化团队为您提供专业的股票、期货量化策略定制，模型设计。如果您有好的策略思路，而不知道怎么用程序表达和编写开发，可以联系我们聚客量化团队，我们为您提供一对一的专属定制服务。
    </p>
  </el-card>
</template>
<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>
<style lang="scss" scoped>
.example {
  p {
    line-height: 40px;
    font-weight: 600;
  }
  img {
    width: 100%;
  }
  .footer {
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    background: #44546a;
  }
}

.isMobile.example {
  display: flex;
  flex-direction: column;
  .el-space,
  .content {
    display: flex;
    justify-content: space-around;

    :deep(.el-space__item) {
      width: 32%;
      margin-right: 0 !important;

      .el-card {
        width: 100%;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    font-size: 8px;
    line-height: 14px;
    width: 100%;
    height: 70px;
  }

  .desc {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
}
</style>
