<template>
  <el-card :class="{ isMobile }">
    <top-title title="自主知识产权"></top-title>
    <br />
    <el-carousel
      style="max-width: 1400px; margin: 0 auto"
      class="certificate"
      :interval="4000"
      type="card"
      :indicator-position="none"
      :height="height + 'px'"
    >
      <el-carousel-item v-for="(item, i) in imageUrls" :key="i">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>
  </el-card>
</template>

<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      height: 600,
      imageUrls: [
        '/static/img/credentials/certificate2.png',
        '/static/img/credentials/certificate3.png',
        '/static/img/credentials/certificate4.png',
        '/static/img/credentials/certificate5.png',
        '/static/img/credentials/certificate6.png',
        '/static/img/credentials/certificate7.png',
        '/static/img/credentials/certificate8.png',
        '/static/img/credentials/certificate9.png',
        '/static/img/credentials/certificate10.png',
        '/static/img/credentials/certificate11.png',
        '/static/img/credentials/certificate12.png',
        '/static/img/credentials/certificate13.png',
        '/static/img/credentials/certificate14.png',
        '/static/img/credentials/certificate15.png'
      ]
    }
  },

  mounted() {
    if (this.isMobile) {
      this.height = 180
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate {
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .certificate {
    width: 80%;
  }
}

.el-carousel__item {
  img {
    width: 100%;
  }
}

.isMobile {
  .certificate {
    .el-carousel {
      height: 100px !important;
    }
    .el-carousel__indicator {
      width: 10px;
    }
  }
}
</style>
