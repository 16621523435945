<template>
  <el-card class="team" :class="{ isMobile }">
    <top-title title="服务团队"></top-title>
    <br />
    <el-row>
      <el-col :span="6" class="first">
        <img src="/static/img/t1.png" />
      </el-col>
      <el-col :span="6">
        <img src="/static/img/t2.png" />
      </el-col>
      <el-col :span="6">
        <img src="/static/img/t3.png" />
      </el-col>
      <el-col :span="6">
        <img src="/static/img/t4.png" />
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import TopTitle from './TopTitle.vue'
export default {
  components: {
    TopTitle
  }
}
</script>

<style lang="scss" scoped>
.team .el-row {
  min-width: 800px;
  max-width: 1400px;
  margin: 0 auto;
}

.first {
  margin-top: -40px;
}
.team.isMobile {
  .first {
    margin-top: -6px;
  }
  .el-row {
    width: 100%;
    min-width: 0;
    img {
      width: 100%;
    }
  }
}
</style>
